import React, { useRef, useEffect } from "react";
import * as THREE from "three";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import { OrbitControls } from "three-stdlib";

const MilkCrateViewer = ({ tintColor }) => {
  const mountRef = useRef(null);

  useEffect(() => {
    // Scene setup
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );
    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.physicallyCorrectLights = true; // Optional for realistic lighting
    renderer.setClearColor(0x000000, 0); // Make the background transparent
    mountRef.current.appendChild(renderer.domElement);

    // Add lighting
    const ambientLight = new THREE.AmbientLight(0x404040);
    const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
    scene.add(ambientLight);
    scene.add(directionalLight);

    // Load an environment map for reflections (e.g., an HDR image)
    const hdrLoader = new RGBELoader();
    hdrLoader.load("/z/studio1.hdr", function (texture) {
      texture.mapping = THREE.EquirectangularReflectionMapping;
      scene.environment = texture;
    });

    // Load the model
    const loader = new OBJLoader();
    loader.load(
      "/z/PlasticCrate3.obj",
      (object) => {
        object.scale.set(0.1, 0.1, 0.1);
        // Apply the color & material to look like glass
        object.traverse((child) => {
          if (child instanceof THREE.Mesh) {
            // Create a new glass-like material
            const glassMaterial = new THREE.MeshPhysicalMaterial({
              color: tintColor, // Pink color in hexadecimal
              metalness: 0.1,
              roughness: 0.1,
              transmission: 0.9, // Slightly less than 1 for a bit of opacity
              opacity: 0.5,
              transparent: true,
              reflectivity: 0.5,
            });

            // Assign the glass material to the mesh
            child.material = glassMaterial;
          }
        });

        scene.add(object);
      },
      (xhr) => {
        console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
      },
      (error) => {
        console.log("An error happened", error);
      }
    );

    // Adjust camera position
    camera.position.set(0, 0, 50);

    // Adding OrbitControls for interactivity
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.05;

    // Handle window resize
    const handleResize = () => {
      const width = mountRef.current.clientWidth;
      const height = mountRef.current.clientHeight;
      renderer.setSize(width, height);
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
    };

    window.addEventListener("resize", handleResize);

    const animate = () => {
      requestAnimationFrame(animate);
      controls.update(); // Update controls on each frame
      renderer.render(scene, camera);
    };

    animate();

    const currentMount = mountRef.current;

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
      // mountRef.current.removeChild(renderer.domElement);
      // Use the stored reference for cleanup
      if (currentMount) {
        currentMount.removeChild(renderer.domElement);
      }
    };
  }, [tintColor]);

  return <div ref={mountRef} className="my3DViewer" />;
};

export default MilkCrateViewer;
