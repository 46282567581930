import React, { useState } from "react";
import MilkCrateViewer from "./MilkCrateViewer";
import "./App.css";

function App() {
  const [tintColor, setTintColor] = useState("#ffc0cb"); // Start with pink
  const [selectedColor, setSelectedColor] = useState("#ffc0cb"); // Temporary color storage

  const applyColorChange = () => {
    setTintColor(selectedColor);
  };

  return (
    <div>
      <MilkCrateViewer tintColor={tintColor} />
      {/* Add UI elements to change color */}

      {/* Choose tint color: */}
      <div className="button-change">
        <label>
          Choose tint color:
          <input
            type="color"
            value={selectedColor}
            onChange={(e) => setSelectedColor(e.target.value)}
          />
        </label>
        <button onClick={applyColorChange}>Apply Color</button>
      </div>
    </div>
  );
}

export default App;
